<template>
  <component :is="isPage?'div':'q-dialog'" id="supplier-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="supplierData._id?'pen':'plus'" :class="supplierData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`member.supplier.${supplierData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: supplierData._id?'member-supplier-edit':'member-supplier-add', params:{supplierId: supplierData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>
      
      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" class="h-100" delay='600' 
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <div class="p-2 flex gap-2 flex-column flex-sm-row">
              <!-- 公司名 -->
              <q-input type="text" outlined hide-bottom-space clearable v-model="supplierData.corpName" class="flex-even m-0 flex-size-4"
                :label="$t('form.corp_name')|capitalize":rules="[val=>Boolean(val)||$t('rule.required'), val=> !val || val.length<=100 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','building']" fixed-width class="text-danger mr-25"/></template>
              </q-input>
              <!-- UEN -->
              <q-input type="text" outlined hide-bottom-space clearable v-model="supplierData.uen" class="flex-even m-0 flex-size-4"
                :label="$t('form.uen')" :rules="[val=>!val||val.length<=10 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','building']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- 联系人 -->
              <q-input type="text" outlined hide-bottom-space clearable v-model="supplierData.name" class="flex-even m-0 flex-size-4"
                :label="$t('form.name')|capitalize" :rules="[ val=> !val || val.length<=50 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','user']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- 电话 -->
              <q-input type="tel" outlined hide-bottom-space clearable v-model="supplierData.phone" :mask="supplierData.mask" unmasked-value
                :label="$t('user.phone')|capitalize" class="flex-even phone m-0 flex-size-4" :rules="[ val=>!val || val.length<=50 || $t('rule.too_long')]">
                <template #prepend>
                  <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-primary mr-50"/>
                  <q-select ref="codeRef" outlined square prefix="+" :options="$global.countries" :label="$t('user.country')" v-model="supplierData.country" class="bg-light-primary mb-0" popup-content-class="mt-1">
                    <template #option="scope">
                      <q-item v-bind="scope.itemProps" class="d-flex gap-1 align-items-center"
                        @click="supplierData.country = scope.opt.value;supplierData.mask = scope.opt.mask;$refs.codeRef.hidePopup()">
                        <q-chip color="primary" text-color="white">{{scope.opt.value}}</q-chip>{{$t('country.'+scope.opt.label)}}
                      </q-item>
                    </template>
                  </q-select>
                </template>
              </q-input>
              <!-- email -->
              <q-input type="email" outlined hide-bottom-space clearable lazy-rules v-model="supplierData.email" :label="$t('form.email')|capitalize" class="flex-even m-0 flex-size-4"
                :rules="[ val=> !val || /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || $t('rule.email'), val=>!val || val.length<=30 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','at']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- URL -->
              <q-input type="text" outlined hide-bottom-space clearable lazy-rules v-model="supplierData.url" :label="$t('form.url')|capitalize" class="flex-even m-0 flex-size-4">
                <template #prepend><fa-icon :icon="['fad','at']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- tags -->
              <q-select v-model="supplierData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input 
                popup-content-class="mt-1" :placeholder="$t('message.enter_add_new')" class="flex-even m-0 flex-size-6">
                <template #selected-item="scope">
                  <q-chip removable dense class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                </template>
                <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
              </q-select>
            </div>

            <!-- 地址 -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','map']" fixed-width />
                {{$t('action.address')}}
              </span>
            </h5>
            <div class="p-2 d-flex gap-2 flex-wrap">
              <q-btn push class="bg-light-primary" style="min-height:5rem" @click.stop="addAddress"><fa-icon icon="plus-circle" size="3x" class="mx-3"/></q-btn>
              <div v-for="(address,i) in supplierData.addresses" :key="i" class="rounded-lg shadow d-flex flex-column" :class="{'bg-light-warning':!address._id}">
                <div class="p-1">
                  <h5 class="text-primary">{{address.name}}</h5>
                  <div>{{address.unit}}</div>
                  <div v-for="item in address.address.split(/[,|.]/)">{{item}}</div>
                  <div>{{address.city}} {{address.zip}}</div>
                </div>
                <q-btn-group spread outline>
                  <q-btn dense push class="bg-primary text-white" style="border-top-left-radius:0" @click.stop="updateAddress(address)"><fa-icon icon="pen"/></q-btn>
                  <q-btn dense push class="bg-danger text-white" style="border-top-right-radius:0" @click.stop="deleteAddress(address, i)"><fa-icon icon="trash" /></q-btn>
                </q-btn-group>
              </div>
            </div>

            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','image']" fixed-width />
                {{$t('form.image')}}
              </span>
            </h5>
            <FileUpload size="160px" model="supplier" :modelId="supplierData._id" :files.sync="supplierData.files" limit="5" class="p-2"/>
          </div>
        </component>

        <div class="d-flex justify-content-between p-2 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="danger" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <AddressDialog ref="addressDialogRef"/>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize, i18n } from '@core/utils/filter'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { Toast, Dialog } from '@core/utils/other'
import useSupplier from './useSupplier'
import FileUpload from '@core/components/file-upload/FileUpload'
import AddressDialog from '@core/components/AddressDialog'

export default {
  name: 'supplier-edit',
  components: {
    FileUpload,
    AddressDialog
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    },
  },
  filters:{
    capitalize
  },
  setup(props) {
    const { route, router } = useRouter()
    const {
      loading,
      supplierData,
      resetSupplier,
      fetchSupplier  
    } = useSupplier()

    // Actions
    let submit = ()=>{
      loading.value = true
      store.dispatch(supplierData.value._id?'supplier/updateSupplier':'supplier/addSupplier', supplierData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetSupplier()
      if (route.value.params.supplierId) {
        loading.value = true
        fetchSupplier(route.value.params.supplierId, ()=>{
          loading.value = false
        })
      }
    }
 
    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async id => {
      resetSupplier()
      isDialogOpen.value = true
      if(id) {
        loading.value = true
        await fetchSupplier(id, ()=> loading.value = false)
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(id?'supplier/updateSupplier':'supplier/addSupplier', supplierData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

// Address
    const addressDialogRef = ref(null)
    const addAddress = () => addressDialogRef.value.open().then(v=>{
      if(!v) return
      if(supplierData._id) {
        loading.value = true
        store.dispatch('supplier/addAddress', {supplierId: supplierData._id, addressData: v}).then(r=>{
          loading.value = true
          supplierData.value.addresses.push({...r.address})
          Toast.fire({icon: 'success', title:i18n('form.success')})
        }).catch(e=>{
          loading.value = false
          Dialog(false).fire({icon:'error', title:e.message})
        })
      } else {
        supplierData.value.addresses.push({...v})
      }
    })
    const updateAddress = address => addressDialogRef.value.open(address).then(v=>{
      if(!v) return
      if(supplierData._id && address._id) {
        loading.value = true
        store.dispatch('supplier/updateAddress', {supplierId: supplierData._id, addressId:address._id, addressData: v}).then(r=>{
          Object.assign(address, {...r.address})
          loading.value = true
          Toast.fire({icon: 'success', title:i18n('form.success')})
        }).catch(e=> {
          loading.value = false
          Dialog(false).fire({icon:'error', title:e.message})
        })
      } else {
        Object.assign(address, {...v})
      }
    })
    const deleteAddress = (address, i) => {
      if(address._id) {
        loading.value = true
        store.dispatch('supplier/removeAddress', {supplierId: supplierData._id, addressId:address._id}).then(r=>{
          loading.value = true
          supplierData.value.addresses.splice(i,1)
          Toast.fire({icon: 'success', title:i18n('form.success')})
        }).catch(e=> {
          loading.value = false
          Dialog(false).fire({icon:'error', title:e.message})
        })
      } else {
        supplierData.value.addresses.splice(i,1)
      }
    }

    return {
      loading, capitalize,
      supplierData,

      // Dailog
      isDialogOpen,
      open,
      cancel,

      clickSubmit,
    
      // Address
      addressDialogRef,
      addAddress,
      updateAddress,
      deleteAddress
    }
  }
}
</script>
<style lang="scss">
#supplier-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .phone {
    .q-field__control-container {
      flex-wrap: nowrap;
      width: 60px
    }
  }
}
</style>