import { ref } from '@vue/composition-api'
import store from '@/store'
import $global from '@/globalVariables'
import supplierStoreModule from './supplierStoreModule'
if (!store.hasModule('supplier')) store.registerModule('supplier', supplierStoreModule)
import { Dialog } from '@core/utils/other'
import { i18n } from '@core/utils/filter'

export default function useSupplierList() {
  const loading = ref(false)
  const emptySupplier = ()=>({
    country: $global.default_country_code,
    mask: $global.default_phone_mask,
    city: $global.default_city,
    tags:[],
    addresses:[]
  })

  const supplierData =  ref(emptySupplier())
  const resetSupplier = () => supplierData.value = emptySupplier()

// Fetch a supplier
  const fetchSupplier = (id, callback) => {
    if (!id) return
    return store.dispatch('supplier/fetchSupplier', id).then(response => {
      supplierData.value = response.supplier
      if (callback) callback(response)
    }).catch(() => supplierData.value = null )
  }

  const deleteSupplier = async id =>{
    const v = await Dialog(true, 'danger').fire({
      icon: 'warning',
      title: i18n('message.if_confirm_delete'),
      confirmButtonText: i18n('action.delete')
    })
    if (v.value) return store.dispatch('supplier/deleteSupplier', id)
  }

  return {
    loading,
    supplierData,
    resetSupplier,
    fetchSupplier,
    deleteSupplier,
  }
}
